import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { GlobalStateService } from '../../../services/global-state.service';
import { CompProductFacade } from '../../services/comp-product-facade';
import { OrderFacade, OrderSummary } from '../../services/order-facade';
import { ProductFacade } from '../../services/product-facade';
import { commentIcon, SVGIcon } from "@progress/kendo-svg-icons";

commentIcon
@Component({
  selector: 'app-summary-step',
  templateUrl: './summary-step.component.html',
  styleUrls: ['./summary-step.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SummaryStepComponent implements OnInit, OnDestroy {

  public comment: SVGIcon = commentIcon;
  private subscriptions = new Subscription();

  orderSummary: OrderSummary;

  @ViewChild(NgForm) sumForm!: NgForm;

  constructor(
    public orderFacade: OrderFacade,
    private compProductFacade: CompProductFacade,
    private productFacade: ProductFacade,
    public glbSvc: GlobalStateService) {
  }

  ngOnInit(): void {
    this.orderFacade.buildSummary(this.productFacade.getSnapShot(), this.compProductFacade.getSnapShot());
    this.subscriptions.add(this.orderFacade.orderSummary$.subscribe(r => { this.orderSummary = r; }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  sendOrder(): void {
    this.orderFacade.sendOrder();
  }

  public goToQtyStep() {
    this.orderFacade.resetOrder();
    this.compProductFacade.hideCompProduct();
    this.productFacade.hideSummary();
    this.productFacade.goToQtyStep();
  };
}
